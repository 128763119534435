import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolesGuardComponent } from './roles-guard.component';
import { RolesGuardDirective } from './roles-guard.directive';

@NgModule({
  declarations: [RolesGuardComponent, RolesGuardDirective],
  imports: [CommonModule],
  exports: [RolesGuardComponent, RolesGuardDirective],
})
export class RolesGuardModule {}
