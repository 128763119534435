import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import { AppState, IAuthState } from './../../../core/reducers/index'

@Directive({
  selector: '[fsRolesGuard]',
})
export class RolesGuardDirective implements OnInit, OnDestroy {
  auth$: Observable<IAuthState>

  #subscription?: Subscription

  private _users?: number[] = []
  private _groups?: number[] = []

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>, private store: Store<AppState>) {
    this.auth$ = this.store.select('auth')
  }
  ngOnDestroy(): void {
    this.#subscription?.unsubscribe()
  }

  async ngOnInit() {
    this.#subscription = this.auth$.subscribe(({ user }) => {
      if (
        !!this._users?.filter(u => user?.user_id === u)?.length ||
        !!this._groups?.filter(g => !!user?.groups?.find(ug => ug.id === g))?.length
      ) {
        this.viewContainer.createEmbeddedView(this.templateRef)
      } else this.viewContainer.clear()
    })
  }

  @Input()
  set fsRolesGuardUsers(users: number[] | undefined) {
    this._users = users
  }
  get fsRolesGuardUsers() {
    return this._users
  }

  @Input() set fsRolesGuard(groups: number[]) {
    this._groups = groups
  }
}
