import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState, IAuthState } from '../../../core/reducers'
import { AuthUser } from '@sauna-net/dto'

@Component({
  selector: 'fs-roles-guard',
  templateUrl: './roles-guard.component.html',
  styleUrls: ['./roles-guard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RolesGuardComponent {
  @Input() groups: number[] = []
  @Input() users: number[] = []
  auth$: Observable<IAuthState>

  constructor(private store: Store<AppState>) {
    this.auth$ = this.store.select('auth')
  }

  checkUserGroups(user?: AuthUser) {
    return (
      !!this.users?.filter(u => user?.user_id === u)?.length || !!this.groups?.filter(g => !!user?.groups?.find(ug => ug.id === g))?.length
    )
  }
}
